import React from 'react'
import OldReferenceId from './OldReferenceId'
import Shipment from './Shipment'
import ExternalCode from './ExternalCode'
import Liquidator from './Liquidator'

const CompanyInfo = (
  {
    requirement,
    shipment,
    setShipment,
    formAlerts,
    setFormAlerts,
    hasExternalCodeGetter,
    liquidator,
    setLiquidator,
    includeLiquidator,
    externalCode,
    setExternalCode,
    reentryCode,
    setReentryCode,
    oldReferenceId,
    setOldReferenceId,
    startDateShipment,
    setStartDateShipment,
    shipmentCode,
    setShipmentCode,
    containerName,
    isEditable,
    isStringExternalCode,
    translatedExternalCode,
    setTranslatedExternalCode
  }
) => {

  return (
    <>
      <div className={'form-row'}>
        <Shipment
          {...
            {
              shipment,
              setShipment,
              requirement,
              formAlerts,
              setFormAlerts,
              containerName,
              startDateShipment,
              setStartDateShipment,
              shipmentCode,
              setShipmentCode,
              isEditable
            }
          }
        />
        <ExternalCode
          {...
            {
              hasExternalCodeGetter,
              formAlerts,
              setFormAlerts,
              containerName,
              reentryCode,
              externalCode,
              setExternalCode,
              isEditable,
              isStringExternalCode,
              translatedExternalCode,
              setTranslatedExternalCode
            }
          }
        />
        { includeLiquidator && (
          <Liquidator
            {...
              {
                liquidator,
                setLiquidator,
                formAlerts,
                setFormAlerts,
                containerName,
                isEditable
              }
            }
          />
        )}
        <OldReferenceId
          {...
            {
              formAlerts,
              setFormAlerts,
              containerName,
              reentryCode,
              setReentryCode,
              externalCode,
              oldReferenceId,
              setOldReferenceId,
              isEditable
            }
          }
        />
      </div>
    </>
  )
}

export default CompanyInfo