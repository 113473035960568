import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {principalApiUrl} from '../../../../../routes/search'
import addErrorAlert from '../../../../../utils/addErrorAlert'
import closeAlert from '../../../../../utils/closeAlert'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import notFoundRespData from '../../../../../utils/notFoundRespData'
import removeAlertByContainer from '../../../../../utils/removeAlertByContainer'
import rutFormat from '../../../../../utils/rutFormat'
import showLoading from '../../../../../utils/showLoading'
import cleanRutFormat from '../../../../../utils/cleanRutFormat'
import FormAlert from '../shared/FormAlert'
import Input from '../../../../../shared/Input'
import Label from '../../../../../shared/Label'

const Principal = (
  {
    countryIso,
    insureeId,
    policies,
    setPolicies,
    policyId,
    setPolicyId,
    formAlerts,
    setFormAlerts,
    containerName,
    editable,
    principalDni,
    setPrincipalDni,
    principalDniType,
    firstRun,
    setFirstRun,
    setPrincipalId,
    setShowRejectButton,
    setContactEmail,
    setContactNumber,
    setBankAccountName,
    setBankAccountNumber,
    setBankAccountType
  }
) => {
  const insurerId = window.insurerId
  const principalDniInput = useRef(null)


  const [principalName, setPrincipalName] = useState(principalDni)

  const buildPoliciesOptions = (newPolicies) => {
    if (newPolicies.length > 0) {
      if (!policyId || policyId === '-1' || newPolicies.filter((policy) => parseInt(policy.value) === parseInt(policyId)).length === 0) {
        setPolicyId(newPolicies.length === 1 ? newPolicies[0].value : '')
      }
      newPolicies = [...newPolicies, {value: '-1', name: 'Otro (rechazar)'}]
    }
    setPolicies(newPolicies)
  }

  const errorResponse = (dni) => {
    // Error
    buildPoliciesOptions([])
    setFormAlerts(addErrorAlert(formAlerts, containerName, 'principal_rut', <span><strong>Error:</strong> no se encontró el titular de dni <strong>'{dni}'</strong>. Se recomienda chequear o rechazar por asegurado no encontrado.</span>))
    setShowRejectButton(true)
  }

  const getPrincipalByDni = async (dni) => {
    if (dni == '') {
      setFormAlerts(removeAlertByContainer(formAlerts, containerName))
      buildPoliciesOptions([])
      setPolicyId('')
      return
    }
    let formData = new FormData();
    formData.append('dni', dni)
    formData.append('dni_type', principalDniType)
    formData.append('insurer_id', insurerId)
    formData.append('policy_id', policyId)
    formData.append('insuree_id', insureeId)
    showLoading()
    await axios({
      url: principalApiUrl,
      method: 'POST',
      data: formData
    })
    .then ((res) => {
      let data = res.data
      if (notFoundRespData(data)) {
        // No encontramos un principal.
        setFirstRun(false)
        return errorResponse(dni)
      }
      // Encontramos un principal.
      buildPoliciesOptions(data.policies)
      setPrincipalName(data.principal_name !== '' ? data.principal_name : dni)
      setPrincipalId(data.principal_id)
      setFormAlerts(removeAlertByContainer(formAlerts, containerName))
      if (firstRun) {
        setFirstRun(false)
      }else {
        setContactEmail(data.principal_email)
        setContactNumber(data.principal_phone)
        setBankAccountName(null)
        setBankAccountType(null)
        setBankAccountNumber('')
      }
    })
    .catch(err =>{
      // Error
      errorResponse(dni)
    })
    .finally(() => {
      closeAlert()
    })
  }

  const formatDni = (value) => {
    var formattedDni;
    //Formateamos solo en el caso en que tengamos un contry iso de chile y no tengamos dni type o que este sea rut
    if (countryIso && (countryIso == 'CL' || countryIso == 'cl') && (!principalDniType || principalDniType == "cl_rut" || principalDniType == '')) {
      formattedDni = rutFormat(value);
    } else {
      formattedDni = value;
    }
    return formattedDni;
  }

  const cleanDniFormat = (value) => {
    var cleanDni;
    if (countryIso && (countryIso == 'CL' || countryIso == 'cl') && (!principalDniType || principalDniType == "cl_rut" || principalDniType == '')) {
      cleanDni = cleanRutFormat(value);
    } else {
      cleanDni = value;
    }
    return cleanDni;
  }

  const handleChange = (event) => {
    setPrincipalDni(formatDni(event.target.value));

    if (firstRun) {
      setFirstRun(false);
    }
  }

  const handleBlur = (event) => {
    getPrincipalByDni(cleanDniFormat(event.target.value));
  }

  useEffect(() => {
    if (policyId && principalDni && policies.length === 0 && firstRun) {
      getPrincipalByDni(cleanDniFormat(principalDni));
    }
  })

  return (
    <div className={'form-group col form-row justify-content-center align-items-center'}>
      <Label htmlFor={'new_principal_rut'} className={'col flex-grow-0'}>Titular</Label>
      {editable ? (
        <>
          <Input
            id={'new_principal_rut'}
            type={'text'}
            placeholder={'Dni titular'}
            value={principalDni}
            onChange={handleChange}
            onBlur={handleBlur}
            ref={principalDniInput}
            className={'col' + getInputErrorClass(formAlerts, 'principal_rut')}
          />
          <FormAlert
            message={getInputErrorMessage(formAlerts, 'principal_rut')}
          />
        </>
      ) : (
        <>
          <Label
            className={'col'}
          >
            <strong>{principalName}</strong>
          </Label>
        </>
      )}

    </div>
  )
}

export default Principal