import React, {useState} from 'react'
import Input from '../../../../../shared/Input'
import Label from '../../../../../shared/Label'
import Select from 'react-select'

const BankAccountInfo = (
  {
    bankAccountName,
    bankAccountType,
    bankAccountNumber,
    setBankAccountName,
    setBankAccountType,
    setBankAccountNumber,
    bankList,
    bankAccountTypes,
    isEditable
  }
) => {

  const [selectedBankAccountName, setSelectedBankAccountName] = useState({value: bankAccountName, label: bankAccountName})
  const [selectedBankAccountType, setSelectedBankAccountType] = useState({value: bankAccountType, label: bankAccountType})

  const handleBankAccountTypeChange = (selected) => {
    setSelectedBankAccountType(selected)
    setBankAccountType(selected.value)
  }

  const handleBankAccountNameChange = (selected) => {
    setSelectedBankAccountName(selected)
    setBankAccountName(selected.value)
  }

  const handleBankAccountNumberChange = (e) => {
    setBankAccountNumber(e.target.value)
  }

  const getBankAccountTypeOptions = () => {
    return bankAccountTypes.map((bankType) => {
      return {
        value: bankType,
        label: bankType
      }
    })
  }

  const getBankAccountNameOptions = () => {
    return bankList.map((bankName) => {
      return {
        value: bankName,
        label: bankName
      }
    })
  }

  return (
    <div className={'col-12'}>
      <div className={'form-row justify-content-around align-items-center mb-2'}>
        <div className={'col'}>
          <Label htmlFor={'bank_account_name'} className={'col flex-grow-0'}>Banco:</Label>
          {isEditable ? (
            <Select
              key={selectedBankAccountName.value}
              name={'bank_account_name'}
              className={'w-100 mb-3'}
              placeholder={'Seleccione un banco'}
              onChange={handleBankAccountNameChange}
              options={getBankAccountNameOptions()}
              value={selectedBankAccountName}
            />
          ) : (
            <strong>{bankAccountName}</strong>
          )}

        </div>
        <div className={'col'}>
          <Label htmlFor={'bank_account_type'} className={'col flex-grow-0'}>Tipo de cuenta:</Label>
          {isEditable ? (
            <Select
              key={selectedBankAccountType.value}
              name={'bank_account_type'}
              className={'w-100 mb-3'}
              placeholder={'Seleccione un tipo de cuenta'}
              onChange={handleBankAccountTypeChange}
              options={getBankAccountTypeOptions()}
              value={selectedBankAccountType}
            />
          ) : (
            <strong>{bankAccountType}</strong>
          )}
        </div>
        <div className={'col'}>
          <Label htmlFor={'bank_account_number'} className={'col flex-grow-0'}>Número de cuenta:</Label>
          {isEditable ? (
            <Input
              name={'bank_account_number'}
              type={'text'}
              placeholder={'Número de cuenta'}
              value={bankAccountNumber}
              onChange={handleBankAccountNumberChange}
              className={'col'}
            />
          ) : (
            <strong>{bankAccountNumber}</strong>
          )}
        </div>
      </div>
    </div>
  )
}
export default BankAccountInfo