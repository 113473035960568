import React, {useRef, useState} from 'react'
import RequirementFormHeader from './form_header'
import HealthForm from './health_form'
import moment from 'moment/moment'
import getLanguage from '../../../utils/getLanguage'
import buildInitialRequirement from '../../../utils/buildInitialRequirement'
import useInterval from '../../../hooks/useInterval'
import showLoading from '../../../utils/showLoading'
import axios from 'axios'
import closeAlert from '../../../utils/closeAlert'
import {requirementEvaluate, requirementEvaluateDentalV1} from '../../../routes/requirements'
import BackgroundsModal from '../../../shared/BackgroundsModal'
import alertWhileRedirect from '../../../utils/alertWhileRedirect'
import Shipment from '../../../models/Shipment'
import Swal from 'sweetalert2'
import { insurersUrl, newRequirementDentalV1 } from '../../../routes/insurers'

const RequirementForm = (
  {
    insurerId,
    countryIso,
    includeLiquidator,
    insurerIsPrimaryInsurance,
    requirementProps,
    hasExternalCodeGetter,
    isBudget,
    externalLiquidator,
    requirementShipment,
    initialPrincipalDni,
    initialPrincipalDniType,
    downloadConsolidatedUrl,
    initialProtectedFiles,
    initialInsuree,
    initialPolicy,
    initialBackgrounds,
    backgroundOptions,
    isChilenaRojo,
    initialReentryCode,
    nIRejectReasonId,
    hospitableRequirementRejectReasonId,
    precedingRejectReasonId,
    initialHospitableRejectDetails,
    initialNIRejectDetails,
    insurerAuditoryType,
    canApprove,
    canHoldBack,
    canRelease,
    forbiddenPolicy,
    showApproveBtn,
    unlockApprove,
    auditors,
    _diagnostic,
    _diagnosticComment,
    healthProvisionTypes,
    isNewRecord,
    insurerProblemProviders,
    insurerDentalV2Enabled, 
    toothOptions,
    sideOptions,
    isEditableProp,
    letterTypesData,
    isFullInsurer,
    isStringExternalCode,
    showInsureeContactInfo,
    ShowInsureeBankAccountInfo,
    initialTranslatedExternalCode,
    initialBankAccountName,
    initialBankAccountType,
    initialBankAccountNumber,
    initialContactEmail,
    initialContactNumber,
    bankList,
    bankAccountTypes
  }
) => {
  window.insurerId = insurerId
  window.isBudget = isBudget
  if (!window.requirementId) {
    window.requirementId = requirementProps ? requirementProps.id : ''
  }
  window.insurerIsPrimaryInsurance = insurerIsPrimaryInsurance
  window.beginningTime = moment().format('YYYY-MM-DD HH:mm:ss ZZ')
  window.language = getLanguage()
  const requirementDoingInterval = 10000000
  const requirementEvaluationInterval = 600000000
  const evaluateSleepTimeout = 1000
  const evaluationTimerId = useRef(null)
  const [requirement, setRequirement] = useState(buildInitialRequirement({...
    {
      requirementShipment,
      initialBackgrounds,
      includeLiquidator,
      hasExternalCodeGetter,
      downloadConsolidatedUrl,
      initialProtectedFiles,
      isChilenaRojo,
      nIRejectReasonId,
      hospitableRequirementRejectReasonId,
      precedingRejectReasonId,
      initialHospitableRejectDetails,
      initialNIRejectDetails,
      initialInsuree,
      initialPolicy,
      backgroundOptions,
      isBudget,
      insurerAuditoryType,
      canApprove,
      canHoldBack,
      canRelease,
      forbiddenPolicy,
      showApproveBtn,
      unlockApprove,
      auditors,
      insurerId,
      countryIso,
      _diagnostic,
      _diagnosticComment,
      isNewRecord,
      insurerProblemProviders,
      isStringExternalCode,
      showInsureeContactInfo,
      ShowInsureeBankAccountInfo,
      initialTranslatedExternalCode,
      initialBankAccountName,
      initialBankAccountType,
      initialBankAccountNumber,
      initialContactEmail,
      initialContactNumber
    },
    backgrounds: [],
    requirement: requirementProps,
    liquidator: externalLiquidator,
    removedBackgrounds: [],
    showRejectButton: false,
    requirePreapproval: false,
    reentryCode: initialReentryCode,
    principalDni: initialPrincipalDni,
    principalDniType: initialPrincipalDniType,
    protectedFiles: [],
    formAlerts: []
  }))

  const formatLetterTypes = (lettersData) => {
    if (!lettersData) {
      return [];
    }
    let lettersInfo = lettersData.map((data) => {
      return {
        id: data.id,
        name: data.name,
        available: data.available
      }
    });
    return lettersInfo;
  };

  const [protectedFiles, _setProtectedFiles] = useState(requirement.protectedFiles)
  const [showHealthForm, _setShowHealthForm] = useState(requirement.showHealthForm)
  const [showDental, _setShowDental] = useState(requirement.showDental)
  const [showDentalV2, _setShowDentalV2] = useState(requirement.showDentalV2)
  const [formAlerts, _setFormAlerts] = useState(requirement.formAlerts)
  const [documents, _setDocuments] = useState(requirement.documents)
  const [comments, _setComments] = useState(requirement.comments)
  const [backgrounds, _setBackgrounds] = useState(requirement.backgrounds)
  const [removedBackgrounds, _setRemovedBackgrounds] = useState(requirement.removedBackgrounds)
  const [showBackgroundsModal, setShowBackgroundsModal] = useState(false)
  const [sendFinishBackground, setSendFinishBackground] = useState(false)
  const [shipment, _setShipment] = useState(requirement.shipment)
  const [externalCode, _setExternalCode] = useState(requirement.external_code)
  const [startDateShipment, _setStartDateShipment] = useState(shipment.startDateShipment)
  const [shipmentCode, _setShipmentCode] = useState(shipment.code)
  const [rejectDetails, _setRejectDetails] = useState(requirement.rejectDetails)
  const [diagnostic, _setDiagnostic] = useState(requirement.diagnostic)
  const [diagnosticComment, _setDiagnosticComment] = useState(requirement.diagnosticComment)
  const [icd_diagnostic, _setIcdDiagnostic] = useState({'icd_diagnostic_id': diagnostic?.id, 'icd_diagnostic_comment': diagnosticComment})
  const [insuree, setInsuree] = useState(initialInsuree)
  const [policy, setPolicy] = useState(initialPolicy)
  const [isEditable, setIsEditable] = useState(isEditableProp)
  const [letterTypes, setLetterTypes] = useState(formatLetterTypes(letterTypesData))

  const [payTo, _setPayTo] = useState(requirement.payment_to)

  const [translatedExternalCode, _setTranslatedExternalCode] = useState(initialTranslatedExternalCode)
  const [bankAccountName, _setBankAccountName] = useState(initialBankAccountName)
  const [bankAccountType, _setBankAccountType] = useState(initialBankAccountType)
  const [bankAccountNumber, _setBankAccountNumber] = useState(initialBankAccountNumber)
  const [contactEmail, _setContactEmail] = useState(initialContactEmail)
  const [contactNumber, _setContactNumber] = useState(initialContactNumber)

  const setTranslatedExternalCode = (value) => {
    _setTranslatedExternalCode(value)
    updateRequirement(['translatedExternalCode'], [value])
  }

  const setBankAccountName = (value) => {
    _setBankAccountName(value)
    updateRequirement(['bankAccountName'], [value])
  }

  const setBankAccountType = (value) => {
    _setBankAccountType(value)
    updateRequirement(['bankAccountType'], [value])
  }

  const setBankAccountNumber = (value) => {
    _setBankAccountNumber(value)
    updateRequirement(['bankAccountNumber'], [value])
  }

  const setContactEmail = (value) => {
    _setContactEmail(value)
    updateRequirement(['contactEmail'], [value])
  }

  const setContactNumber = (value) => {
    _setContactNumber(value)
    updateRequirement(['contactNumber'], [value])
  }

  const setRejectDetails = (value) => {
    _setRejectDetails(value)
    updateRequirement(['rejectDetails'], [value])
  }

  const setExternalCode = (value) => {
    _setExternalCode(value)
    updateRequirement(['external_code'], [value])
  }

  const setShipment = (value) => {
    _setShipment(value)
    updateRequirement(['shipment'], [value])
  }

  const setDiagnostic = (value) => {
    _setDiagnostic(value)
    updateRequirement(['icd_diagnostics'], [{"icd_diagnostic_id": value, "comment": diagnosticComment}])
  }

  const setDiagnosticComment = (value) => {
    _setDiagnosticComment(value)
    updateRequirement(['icd_diagnostics'], [{"icd_diagnostic_id": diagnostic, "comment": value}])
  }

  const updateRequirementShipment = (key, value) => {
    let newShipment = requirement.shipment
    newShipment[key] = value
    updateRequirement(['shipment'], [newShipment])
  }
  const setStartDateShipment = (value) => {
    _setStartDateShipment(value)
    updateRequirementShipment('receipt_date', value)
  }
  const setShipmentCode = (value) => {
    _setShipmentCode(value)
    updateRequirementShipment('code', value)
  }

  const setBackgrounds = (value) => {
    _setBackgrounds(value)
    updateRequirement(['backgrounds'], [value])
  }

  const setRemovedBackgrounds = (value) => {
    _setRemovedBackgrounds(value)
    updateRequirement(['removedBackgrounds'], [value])
  }
  const setComments = (value) => {
    _setComments(value)
    updateRequirement(['comments'], [value])
  }

  const setPayTo = (value) => {
    _setPayTo(value)
    updateRequirement(['payment_to'], [value])
  }

  const setDocuments = (newDocuments, newEvaluation, sleepTime) => {
    _setDocuments(newDocuments)
    updateRequirement(['documents'], [newDocuments], newEvaluation, sleepTime)
  }

  const makeNewEvaluation = (redirectOnSuccess, requirementToEvaluate, sleepTime) => {
    let timeout = sleepTime ? sleepTime : evaluateSleepTimeout
    clearTimeout(evaluationTimerId.current)
    evaluationTimerId.current = setTimeout(() => evaluateRequirement(redirectOnSuccess, requirementToEvaluate, false), timeout)
  }

  const updateRequirement = (keys, values, newEvaluation, sleepTime) => {
    let newRequirement = requirement
    keys.forEach((key, index) => {
      newRequirement[key] = values[index]
    })
    setRequirement(newRequirement)
    if (newEvaluation) {
      makeNewEvaluation(false, newRequirement, sleepTime)
    }
  }

  const setProtectedFiles = (value) => {
    _setProtectedFiles(value)
    updateRequirement(['protectedFiles'], [value])
  }

  const setShowHealthForm = (value) => {
    _setShowHealthForm(value)
    updateRequirement(['showHealthForm', 'insurance_type'], [value, 'health'])
  }

  const setShowDentalV2 = (value) => {
    _setShowDentalV2(value)
    updateRequirement(['showDentalV2', 'insurance_type'], [value, 'dental'])
  }

  const setShowDental = (value) => {
    _setShowDental(value)
    // Redirigimos -> Modificar ruta a la cual debe redirigir
    window.location.href = insurersUrl + `/${insurerId}` + newRequirementDentalV1
  }

  const setFormAlerts = (value) => {
    _setFormAlerts(value)
    updateRequirement(['formAlerts'], [value])
  }

  const sendRequirementToEvaluation = async (loading, requirementToEvaluate) => {
    if (!requirementToEvaluate.canBeEvaluated) {
      // Si no puede ser evaluado el gasto, salimos no más
      return
    }

    if (loading) {
      showLoading()
    }
    let errMsg = 'Ocurrió un inconveniente al subir el archivo. Intente nuevamente'
    return await axios({
      url: requirementEvaluate,
      method: 'POST',
      data: requirementToEvaluate.asJson
    })
    .then ((res) => {
      let data = res.data,
        success = data.success
      if (success) {
        return {
          evaluatedRequirement: data.requirement,
          redirectTo: data.redirect_to,
          success: true,
          errMsg: ''
        }
      }
    })
    .catch(err => {
      console.log('ERROR al enviar a evaluación el ' + requirementToEvaluate.resourceType + ': ' + err)
      switch (err.code) {
        case 'ERR_BAD_REQUEST':
          errMsg = 'No pudimos realizar la acción solicitada. Pudo haber perdido la sesión. Revise y vuelva a intentar'
          if (err.response?.data?.type === 'invalid_status') {
            errMsg = err.response.data.message
          }
          break
        case 'ERR_NETWORK':
          errMsg = 'No pudimos realizar la acción solicitada. Revise su conexión a internet y vuelva a intentar'
          break
      }
      return new Promise((resolve, _) => {
        resolve({
          evaluatedRequirement: requirementToEvaluate,
          redirectTo: '',
          errMsg: errMsg,
          success: false
        })
      })
    })
    .finally(() => {
      if (loading) {
        closeAlert()
      }
    })
  }

  const evaluateRequirement = (redirectOnSuccess, requirementToEvaluate, showErrMsg) => {
    clearTimeout(evaluationTimerId.current)
    sendRequirementToEvaluation(redirectOnSuccess, requirementToEvaluate)
      .then(({evaluatedRequirement, redirectTo, success, errMsg}) => {
        if (success) {
          if (redirectOnSuccess && redirectTo) {
            alertWhileRedirect('success', 'Todo en orden', 'Operación realizada con exito. Espere unos momentos mientras es redirigido.', redirectTo)
          }else if (evaluatedRequirement) {
            let newExternalCode = evaluatedRequirement.external_code || '',
              newShipment = evaluatedRequirement.shipment,
              newShipmentCode = newShipment.code || '',
              newRejectDetails = evaluatedRequirement.rejects,
              systemShipment = new Shipment(newShipment),
              newDocuments = evaluatedRequirement.documents.map((document) => {
                // si uno de los provision details recién creados no fue incluido en el request al backend, lo agregamos
                let documentToEvaluate = requirementToEvaluate.asJson.documents.find((doc) => doc.front_id === document.front_id)
                if (documentToEvaluate && document.provision_details.length != documentToEvaluate.provision_details.length) {
                  let notIncludedPd = documentToEvaluate.provision_details.filter((pd) => !document.provision_details.find((p) => p.id === pd.id))
                  document.provision_details.push(...notIncludedPd)
                }
                return document
              })
            setExternalCode(newExternalCode)
            setShipment(systemShipment)
            setShipmentCode(newShipmentCode)
            setStartDateShipment(systemShipment.startDateShipment)
            // Para los rechazos y documentos necesitamos un tratamiento especial
            // Así que se lo delegamos al Requirement
            setRejectDetails(requirement.buildRejectsFromEvaluation(newRejectDetails))
            setDocuments([...requirement.buildDocumentsFromEvaluation(newDocuments, documents)])
          }
        }else if (showErrMsg) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: errMsg,
            confirmButtonColor: '#0281c4'
          })
        }
      })
  }

  const handleFinishBackgroundBtnClick = () => {
    setSendFinishBackground(true)
    setShowBackgroundsModal(false)
    
    // if (requirement.isHealth) {
    //   makeNewEvaluation(true, requirement, 100)
    // }
  }

  useInterval(() => {
    if (requirement.can_set_doing) {
      requirement.setDoing()
    }
  }, requirementDoingInterval)

  useInterval(() => {
    makeNewEvaluation(false, requirement, 100)
  }, requirementEvaluationInterval)

  const holdBackRequirementCallback = (holdBackComments) => {
    let requirementToEvaluate = requirement
    requirementToEvaluate.holdBackFlag = true
    requirementToEvaluate.holdBackComments = holdBackComments
    evaluateRequirement(true, requirementToEvaluate, true)
  }

  const saveRequirementCallback = () => {
    let requirementToEvaluate = requirement
    requirementToEvaluate.saveFlag = true
    evaluateRequirement(true, requirementToEvaluate, true)
  }

  const finishRequirementCallback = () => {
    let requirementToEvaluate = requirement
    requirementToEvaluate.finishFlag = true
    evaluateRequirement(true, requirementToEvaluate, true)
  }

  const auditRequirementCallback = (auditorsIds) => {
    let requirementToEvaluate = requirement
    requirementToEvaluate.auditoryFlag = true
    requirementToEvaluate.auditorsIds = auditorsIds
    evaluateRequirement(true, requirementToEvaluate, true)
  }

  return (
    <>
      <div className={'requirement-insurance-type'}>
        <h5>{requirement.insuranceTypeText[0]}<strong>{requirement.insuranceTypeText[1]}</strong></h5>
      </div>
      <RequirementFormHeader
        {...
          {
            requirement,
            updateRequirement,
            formAlerts,
            setFormAlerts,
            setShowHealthForm,
            setShowDental,
            setShowDentalV2,
            protectedFiles,
            setProtectedFiles,
            comments,
            setComments,
            backgrounds,
            setShowBackgroundsModal,
            removedBackgrounds,
            sendFinishBackground,
            setSendFinishBackground,

            // Valores que pueden cambiar al evaluar un gasto
            shipment,
            shipmentCode,
            startDateShipment,
            externalCode,
            rejectDetails,
            payTo,

            // Sus respectivos seteadores
            setShipment,
            setShipmentCode,
            setStartDateShipment,
            setExternalCode,
            setRejectDetails,
            setPayTo,
            setInsuree,
            setPolicy,
            insurerDentalV2Enabled,

            // Para el show
            isEditable,
            isFullInsurer,

            // Campos utilizados para integración Chubb
            bankList,
            bankAccountTypes,
            isStringExternalCode,
            showInsureeContactInfo,
            ShowInsureeBankAccountInfo,
            translatedExternalCode,
            bankAccountName,
            bankAccountType,
            bankAccountNumber,
            contactEmail,
            contactNumber,
            setTranslatedExternalCode,
            setBankAccountName,
            setBankAccountType,
            setBankAccountNumber,
            setContactEmail,
            setContactNumber
          }
        }
      />
    {isEditable && (
      <BackgroundsModal
        {...
          {
            initialBackgrounds,
            backgrounds,
            setBackgrounds,
            showBackgroundsModal,
            setShowBackgroundsModal,
            comments,
            setComments,
            formAlerts,
            backgroundOptions,
            handleFinishBackgroundBtnClick,
            removedBackgrounds,
            setRemovedBackgrounds
          }}

        title={'Rechazo total: Falta de antecedentes / Observaciones'}
      />
    )}

      {showHealthForm && (
        <HealthForm
          {...
            {
              requirement,
              updateRequirement,
              formAlerts,
              setFormAlerts,
              protectedFiles,
              setShowBackgroundsModal,
              holdBackRequirementCallback,
              saveRequirementCallback,
              finishRequirementCallback,
              auditRequirementCallback,
              healthProvisionTypes,
              insuree,
              policy,
              insurerProblemProviders,
              setDiagnostic,
              diagnostic,
              setDiagnosticComment,
              diagnosticComment,

              // Valores que pueden cambiar al evaluar un gasto
              documents,
              setDocuments,
              isEditable,
              letterTypes,
              isFullInsurer
            }
          }
          policyId={requirement.policy_id}
          requirementType={'health'}
        />
      )}
      {showDentalV2 && (
        <HealthForm
          {...
            {
              requirement,
              updateRequirement,
              formAlerts,
              setFormAlerts,
              protectedFiles,
              setShowBackgroundsModal,
              holdBackRequirementCallback,
              saveRequirementCallback,
              finishRequirementCallback,
              auditRequirementCallback,
              healthProvisionTypes,
              insuree,
              policy,
              insurerProblemProviders,
              setDiagnostic,
              diagnostic,
              setDiagnosticComment,
              diagnosticComment,
              toothOptions,
              sideOptions,

              // Valores que pueden cambiar al evaluar un gasto
              documents,
              setDocuments,
              isEditable,
              letterTypes,
              isFullInsurer
            }
          }
          policyId={requirement.policy_id}
          requirementType={'dental'}
        />
      )}
    </>
  )
}

export default RequirementForm