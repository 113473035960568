import {v4} from 'uuid'
import Shipment from './Shipment'
import Document from './Document'
import axios from 'axios'
import {requirementSetDoing} from '../routes/requirements'
import Reject from './Reject'

export default class Requirement {

  constructor(props) {
    this.systemId = v4()

    // Vienen del requirement del backend
    this.id = props.id
    this.policy_id = props.policy_id || ''
    this.insuree_id = props.insuree_id || ''
    this.insurerId = props.insurerId || ''
    this.countryIso = props.countryIso || ''
    this.comments = props.comments || ''
    this.external_code = props.external_code || ''
    this.old_reference_id = props.old_reference_id || ''
    this.url = props.url || ''
    this.insurance_type = props.insurance_type || 'undefined'
    this.principalId = props.principal_id || ''
    this.payment_to = props.payment_to

    // Lo armamos con info del backend
    this.shipment = props.shipment || new Shipment({})
    this.documents = props.documents && props.documents.length > 0 ? this.buildDocuments(props.documents) : []
    this.diagnostic = props._diagnostic || null
    this.diagnosticComment = props._diagnosticComment || ''
    this.icd_diagnostics = props.icd_diagnostics || {"icd_diagnostic_id": (this.diagnostic ? this.diagnostic.id : null), "comment": this.diagnosticComment}

    // Cosas que necesitamos para el frontend
    this.liquidator = props.liquidator
    this.showRejectButton = props.showRejectButton
    this.backgrounds = props.backgrounds
    this.initialBackgrounds = props.initialBackgrounds
    this.removedBackgrounds = props.removedBackgrounds
    this.reentryCode = props.reentryCode || ''
    this.requirePreapproval = props.requirePreapproval
    this.principalDni = props.principalDni || ''
    this.principalDniType = props.principalDniType || ''
    this.protectedFiles = props.protectedFiles
    this.initialProtectedFiles = props.initialProtectedFiles
    this.showHealthForm = this.insurance_type === 'health'
    this.formAlerts = props.formAlerts
    this.includeLiquidator = props.includeLiquidator
    this.hasExternalCodeGetter = props.hasExternalCodeGetter
    this.downloadConsolidatedUrl = props.downloadConsolidatedUrl
    this.isChilenaRojo = props.isChilenaRojo
    this.nIRejectReasonId = props.nIRejectReasonId
    this.hospitableRequirementRejectReasonId = props.hospitableRequirementRejectReasonId
    this.precedingRejectReasonId = props.precedingRejectReasonId
    this.initialHospitableRejectDetails = props.initialHospitableRejectDetails
    this.initialNIRejectDetails = props.initialNIRejectDetails
    this.initialInsuree = props.initialInsuree || ''
    this.backgroundOptions = props.backgroundOptions
    this.isBudget = props.isBudget
    this.rejectDetails = props.rejectDetails && props.rejectDetails.length > 0 ? this.buildRejects(props.rejectDetails) : []

    this.canApprove = props.canApprove || false
    this.canHoldBack = props.canHoldBack || false
    this.canRelease = props.canRelease || false
    this.forbiddenPolicy = props.forbiddenPolicy || false
    this.showApproveBtn = props.showApproveBtn || false
    this.unlockApprove = props.unlockApprove || false
    this.holdBackFlag = props.holdBackFlag || false
    this.holdBackComments = props.holdBackComments || ''
    this.auditorsIds = props.auditorsIds || []
    this.auditoryFlag = props.auditoryFlag || false
    this.auditors = props.auditors || []
    this.finishFlag = props.finishFlag || false
    this.saveFlag = props.saveFlag || false

    this.isNewRecord = props.isNewRecord || false

    this.insurerProblemProviders = props.insurerProblemProviders || []
    this.showDentalV2 = this.insurance_type == "dental"

    this.isStringExternalCode = props.isStringExternalCode  || false
    this.showInsureeContactInfo = props.showInsureeContactInfo  || false
    this.ShowInsureeBankAccountInfo = props.ShowInsureeBankAccountInfo  || false

    this.translatedExternalCode = props.translatedExternalCode || ''
    this.bankAccountName = props.bankAccountName || ''
    this.bankAccountType = props.bankAccountType || ''
    this.bankAccountNumber = props.bankAccountNumber || ''
    this.contactEmail = props.contactEmail || ''
    this.contactNumber = props.contactNumber || ''
  }

  // Getters
  get documentWithRequiredAudit () {
    return this.documents.filter((document) => {
      return document.requiredAudit
    })
  }

  get requiredAudit () {
    return this.documentWithRequiredAudit.length > 0
  }

  get can_set_doing () {
    return this.principalId && this.id
  }

  get canBeEvaluated () {
    return (!!this.id || this.isNewRecord) && (this.isHealth || this.isDental) //Agregamos evaluación de gastos dentales
  }

  get selectedAuditors () {
    let resp = []
    this.auditorsIds.forEach((auditorId) => {
      this.auditors.forEach(( auditors) => {
        auditors[1].forEach((auditor) => {
          if (parseInt(auditor.id) === parseInt(auditorId)) {
            resp.push({
              value: auditor.id,
              label: auditor.name
            })
          }
        })
      })
    })
    return resp
  }

  get backgroundRejectDetails () {
    return this.rejectDetails.filter((rejectDetail) => {
      return rejectDetail.isBackgroundRejectDetail
    })
  }

  get inactiveBackgroundRejects () {
    return this.backgroundRejectDetails.filter((rejectDetail) => {
      return !rejectDetail.active
    })
  }

  get activeBackgroundReject () {
    return this.inactiveBackgroundRejects.length === 0
  }

  get hospitableRejectDetails () {
    return this.rejectDetails.filter((rejectDetail) => {
      return rejectDetail.isHospitableRejectDetail
    })
  }

  get inactiveHospitableRejects () {
    return this.hospitableRejectDetails.filter((rejectDetail) => {
      return !rejectDetail.active
    })
  }

  get activeHospitableReject () {
    return this.inactiveHospitableRejects.length === 0
  }

  get niRejectDetails () {
    return this.rejectDetails.filter((rejectDetail) => {
      return rejectDetail.isNiRejectDetail
    })
  }

  get inactiveNiRejects () {
    return this.niRejectDetails.filter((rejectDetail) => {
      return !rejectDetail.active
    })
  }

  get activeNiReject () {
    return this.inactiveNiRejects.length === 0
  }

  get hasRejectDetails () {
    return this.rejectDetails.length > 0
  }

  get resourceType () {
    return this.isBudget ? 'Budget' : 'Expense'
  }

  get liquidatorId () {
    return this.liquidator ? this.liquidator.id : null
  }

  get rejectsDetailsAsJson () {
    return this.rejectDetails.map((rejectDetail) => {
      return rejectDetail.asJson
    })
  }

  get documentsAsJson () {
    return this.documents.map((document) => {
      return document.asJson
    })
  }

  get asJson () {
    return {
      id: this.id,
      insurance_type: this.insurance_type,
      external_code: this.external_code,
      external_liquidator_id: this.liquidatorId,
      old_reference_id: this.old_reference_id,
      policy_id: this.policy_id,
      insuree_id: this.insuree_id,
      insurer_id: this.insurerId,
      comments: this.comments,
      save_flag: this.saveFlag,
      hold_back_comments: this.holdBackComments,
      hold_back_flag: this.holdBackFlag,
      finish_flag: this.finishFlag,
      requirement_auditory_provider_ids: this.auditorsIds,
      requirement_auditory_flag: this.auditoryFlag,
      shipment: this.shipment.asJson,
      rejects: this.rejectsDetailsAsJson,
      documents: this.documentsAsJson,
      diagnostic: this.icd_diagnostics,
      payment_to: this.payment_to,
      contact_email: this.contactEmail,
      contact_number: this.contactNumber,
      bank_account_name: this.bankAccountName,
      bank_account_type: this.bankAccountType,
      bank_account_number: this.bankAccountNumber
    }
  }

  get isHealth () {
    return this.insurance_type === 'health'
  }

  get isDental () {
    return this.insurance_type === 'dental'
  }

  get insuranceTypeText () {
    let insuranceType = this.isHealth ? 'Salud' : (this.isDental ? 'Dental' : 'Indeterminado')
    return ['Tipo de seguro: ', insuranceType]
  }

  // Methods
  buildDocuments = (documents) => {
    return documents.map((document) => {
      return new Document(document)
    })
  }

  setDoing = async () => {
    let formData = new FormData()
    $('form input[name=utf8],form input[name=authenticity_token]').each(function () {
      let $elem = $(this)
      formData.append($elem.attr('name'),$elem.val())
    })
    formData.append('resource_id', this.id)
    formData.append('resource_type', this.resourceType)
    formData.append('resource_group_id', this.principalId)
    await axios({
      url: requirementSetDoing,
      method: 'POST',
      data: formData
    }).catch(err =>{
      console.log(err)
    })
  }

  buildRejects = (rejectDetails) => {
    return rejectDetails.map((rejectDetail) => {
      return new Reject(rejectDetail)
    })
  }

  buildRejectsFromEvaluation = (rejectDetails) => {
    return rejectDetails.map((rejectDetail) => {
      let originalReject = this.rejectDetails.filter((originalRejectDetail) => {
        return originalRejectDetail.systemId === rejectDetail.front_id
      })[0]
      if (originalReject) {
        // Lo encontramos, así que lo modificaremos con los nuevos datos
        return originalReject.buildFromEvaluation(rejectDetail)
      }
      // Si no lo encontramos, quiere decir que es un nuevo rechazo
      return new Reject(rejectDetail)
    })
  }

  buildDocumentsFromEvaluation = (evaluatedDocuments, documents) => {
    return evaluatedDocuments.map((evaluatedDocument) => {
      let systemDocument = documents.filter((doc) => {
        return doc.systemId === evaluatedDocument.front_id
      })[0]
      if (systemDocument) {
        // Lo encontramos, así que lo modificaremos con los nuevos datos
        return systemDocument.buildFromEvaluation(evaluatedDocument)
      }
    })
  }

}