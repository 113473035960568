import React, {useRef, useEffect} from 'react'
import axios from 'axios'
import Input from '../../../../../shared/Input'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import addErrorAlert from '../../../../../utils/addErrorAlert'
import removeAlertByContainer from '../../../../../utils/removeAlertByContainer'
import Label from '../../../../../shared/Label'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import FormAlert from '../shared/FormAlert'
import {principalEmailUrl} from '../../../../../routes/insurees'
import showLoading from '../../../../../utils/showLoading'
import closeAlert from '../../../../../utils/closeAlert'

const ContactInfo = (
  {
    contactEmail,
    contactNumber,
    setContactEmail,
    setContactNumber,
    formAlerts,
    setFormAlerts,
    containerName,
    isEditable,
    policyId,
    principalRut,
    insurerId
  }
) => {

  const contactEmailInput = useRef(null)
  const contactPhoneInput = useRef(null)

  useEffect(() => {
    const fetchContactEmail = async () => {
      if (!policyId || !principalRut || !insurerId) return;

      try {
        showLoading();
        const response = await axios.get(principalEmailUrl, {
          params: {
            policy_id: policyId,
            principal_rut: principalRut, 
            insurer_id: insurerId
          }
        });
        setContactEmail(response.data.contact_email || '');
      } catch (error) {
        setContactEmail('');
      } finally {
        closeAlert();
      }
    };

    fetchContactEmail();
  }, [policyId, principalRut, insurerId]);

  const handleContactEmailChange = (e) => {
    setContactEmail(e.target.value)
  }

  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value)
  }

  const handleContactEmailBlur = () => {
    // Validar si contactEmail es un correo válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(contactEmail)) {
      setFormAlerts(addErrorAlert(formAlerts, containerName, 'contact_email', <span><strong>Error:</strong> Correo electrónico no válido</span>))
      contactEmailInput.current.focus()
    } else {
      setFormAlerts(removeAlertByContainer(formAlerts, containerName))
    }
  }

  return (
    <div className={'col-12'}>
      <div className={'form-row justify-content-start align-items-center mb-2'}>
        <div className={'col'}>
          <Label htmlFor={'contact_email'} className={'col flex-grow-0'}>Correo de contacto:</Label>
          {isEditable ? (
            <>
              <Input
                name={'contact_email'}
                type={'text'}
                placeholder={'Correo de contacto'}
                value={contactEmail}
                onChange={handleContactEmailChange}
                onBlur={handleContactEmailBlur}
                ref={contactEmailInput}
                className={'col ' + getInputErrorClass(formAlerts, 'contact_email')}
              />
              <FormAlert
                message={getInputErrorMessage(formAlerts, 'contact_email')}
              />
            </>
          ) : (
            <strong>{contactEmail}</strong>
          )}
        </div>
        <div className={'col'}>
          <Label htmlFor={'contact_phone'} className={'col flex-grow-0'}>Teléfono de contacto:</Label>
          {isEditable ? (
            <Input
              name={'contact_phone'}
              type={'text'}
              placeholder={'Teléfono de contacto'}
              value={contactNumber}
              onChange={handleContactNumberChange}
              ref={contactPhoneInput}
              className={'col'}
            />
          ) : (
            <strong>{contactNumber}</strong>
          )}
        </div>
      </div>
    </div>
  )
}
export default ContactInfo