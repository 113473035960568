import baseApiV2RequirementsSearch from './baseApiV2RequirementsSearch'

export const requirePreapprovalApiUrl = baseApiV2RequirementsSearch + '/require_preaproval_value'

export const shipmentApiUrl = baseApiV2RequirementsSearch + '/shipment'

export const principalApiUrl = baseApiV2RequirementsSearch + '/principal'

export const requirementByExternalCode = baseApiV2RequirementsSearch + '/requirement_by_external_code'

export const requirementByTranslatedExternalCode = baseApiV2RequirementsSearch + '/requirement_by_translated_external_code'

export const requirementById = baseApiV2RequirementsSearch + '/requirement'

export const liquidatorsApiUrl = baseApiV2RequirementsSearch + '/liquidators'

export const providersApiUrl = baseApiV2RequirementsSearch + '/health_providers'

export const provisionsApiUrl = baseApiV2RequirementsSearch + '/health_provisions'

export const provisionApiUrl = baseApiV2RequirementsSearch + '/health_provision'

export const documentTypesApiUrl = baseApiV2RequirementsSearch + '/document_types'

export const documentUrl = baseApiV2RequirementsSearch + '/document'

export const policyInstanceUrl = baseApiV2RequirementsSearch + '/policy_instance'

export const prescriptionsApiUrl = baseApiV2RequirementsSearch + '/prescriptions'