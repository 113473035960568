import Requirement from '../models/Requirement'
import Shipment from '../models/Shipment'
const buildInitialRequirement = (
  {
    requirement,
    requirementShipment,
    backgrounds,
    initialBackgrounds,
    removedBackgrounds,
    liquidator,
    showRejectButton,
    reentryCode,
    requirePreapproval,
    principalDni,
    principalDniType,
    protectedFiles,
    showHealthForm,
    formAlerts,
    includeLiquidator,
    downloadConsolidatedUrl,
    hasExternalCodeGetter,
    initialProtectedFiles,
    isChilenaRojo,
    nIRejectReasonId,
    hospitableRequirementRejectReasonId,
    precedingRejectReasonId,
    initialHospitableRejectDetails,
    initialNIRejectDetails,
    initialInsuree,
    backgroundOptions,
    isBudget,
    canApprove,
    canHoldBack,
    canRelease,
    forbiddenPolicy,
    showApproveBtn,
    unlockApprove,
    auditors,
    insurerId,
    countryIso,
    _diagnostic,
    _diagnosticComment,
    isNewRecord,
    insurerProblemProviders,
    isStringExternalCode,
    showInsureeContactInfo,
    ShowInsureeBankAccountInfo,
    initialTranslatedExternalCode,
    initialBankAccountName,
    initialBankAccountType,
    initialBankAccountNumber,
    initialContactEmail,
    initialContactNumber
  }
) => {
  let shipment = new Shipment(requirementShipment || {})
  return new Requirement({... requirement, ...
    { ...
      {
        liquidator,
        showRejectButton,
        shipment,
        backgrounds,
        initialBackgrounds,
        removedBackgrounds,
        reentryCode,
        requirePreapproval,
        principalDni,
        principalDniType,
        protectedFiles,
        showHealthForm,
        formAlerts,
        includeLiquidator,
        hasExternalCodeGetter,
        downloadConsolidatedUrl,
        initialProtectedFiles,
        isChilenaRojo,
        nIRejectReasonId,
        hospitableRequirementRejectReasonId,
        precedingRejectReasonId,
        initialHospitableRejectDetails,
        initialNIRejectDetails,
        initialInsuree,
        backgroundOptions,
        isBudget,
        canApprove,
        canHoldBack,
        canRelease,
        forbiddenPolicy,
        showApproveBtn,
        unlockApprove,
        auditors,
        insurerId,
        countryIso,
        _diagnostic,
        _diagnosticComment,
        isNewRecord,
        insurerProblemProviders,
        isStringExternalCode,
        showInsureeContactInfo,
        ShowInsureeBankAccountInfo,
      },
      translatedExternalCode: initialTranslatedExternalCode,
      bankAccountName: initialBankAccountName,
      bankAccountType: initialBankAccountType,
      bankAccountNumber: initialBankAccountNumber,
      contactEmail: initialContactEmail,
      contactNumber: initialContactNumber
    }
  })
}

export default buildInitialRequirement