import React, {useState} from 'react'
import axios from 'axios'
import {requirementByExternalCode, requirementByTranslatedExternalCode} from '../../../../../routes/search'
import addErrorAlert from '../../../../../utils/addErrorAlert'
import closeAlert from '../../../../../utils/closeAlert'
import getInputErrorClass from '../../../../../utils/getInputErrorClass'
import getInputErrorMessage from '../../../../../utils/getInputErrorMessage'
import notFoundRespData from '../../../../../utils/notFoundRespData'
import removeAlertByField from '../../../../../utils/removeAlertByField'
import showLoading from '../../../../../utils/showLoading'
import FormAlert from '../shared/FormAlert'
import Input from '../../../../../shared/Input'
import Label from '../../../../../shared/Label'
import Select from '../../../../../shared/Select'
import EditableValue from '../shared/EditableValue'

const RequirementId = (
  {
    hasExternalCodeGetter,
    formAlerts,
    setFormAlerts,
    containerName,
    reentryCode,
    externalCode,
    setExternalCode,
    isEditable,
    isStringExternalCode,
    translatedExternalCode,
    setTranslatedExternalCode
  }
) => {

  const [editable, setEditable] = useState(externalCode === '')
  const [externalCodeState, setExternalCodeState] = useState(externalCode === '' ? '1' : '2')
  const [disabledExternalCode, setDisabledExternalCode] = useState(externalCode === '')
  const insurerId = window.insurerId
  const requirementId = window.requirementId
  const requirementType = window.isBudget ? 'budget' : 'expense'

  const getRequirementType = () => {
    return requirementType === 'budget' ? 'Presupuesto' : 'Gasto'
  }

  const requirementByExternalCodeErrorResponse = () => {
    setFormAlerts(addErrorAlert(formAlerts, containerName, 'external_code', <span>Ya hay un {getRequirementType()} según folio externo con ese valor. Sólo podra utilizarlo en caso de reingreso. Por favor verifique y cambie este número o no podrá completar el ingreso.</span>))
  }

  const getRequirementByExternalCode = async () => {
    let formData = new FormData()
    formData.append('external_code', externalCode)
    formData.append('requirement_id', requirementId)
    formData.append('requirement_type', requirementType)
    formData.append('insurer_id', insurerId)
    showLoading()
    await axios({
      url: requirementByExternalCode,
      method: 'POST',
      data: formData
    })
    .then ((res) => {
      let data = res.data
      if (notFoundRespData(data)) {
        // No encontramos un gasto con este external_code. Podemos utilizarlo
        setEditable(false)
        return setFormAlerts(removeAlertByField(formAlerts, 'external_code'))
      }
      // Encontramos un gasto con este external_code. Por lo tanto, no puede ser utilizado de nuevo a menos que sea reingreso
      if (externalCode !== reentryCode) {
        requirementByExternalCodeErrorResponse()
      }
    })
    .catch(err =>{
      // Error
      requirementByExternalCodeErrorResponse()
    })
    .finally(() => {
      closeAlert()
    })
  }

  const handleExternalCodeChange = (event) => {
    let value = event.target.value
    setExternalCode(value)
  }

  const handleExternalCodeBlur = () => {
    getRequirementByExternalCode()
  }

  const handleExternalCodeStateChange = (event) => {
    let value = event.target.value,
      disableValue = true,
      externalCodeValue = externalCode
    setExternalCodeState(value)
    switch (value) {
      case '1':
      case '3':
        externalCodeValue = ''
        setFormAlerts(removeAlertByField(formAlerts, 'external_code'))
        break;
      case '2':
        disableValue = false
        break;
      default:
    }
    setDisabledExternalCode(disableValue)
    setExternalCode(externalCodeValue)
  }

  const getRequirementByTranslatedExternalCode = async () => {
    let formData = new FormData()
    formData.append('translated_external_code', translatedExternalCode)
    formData.append('external_code', externalCode)
    formData.append('requirement_id', requirementId)
    formData.append('requirement_type', requirementType)
    formData.append('insurer_id', insurerId)
    showLoading()
    await axios({
      url: requirementByTranslatedExternalCode,
      method: 'POST',
      data: formData
    })
      .then ((res) => {
        let data = res.data
        if (data.success) {
          // Podemos utilizar el codigo externo
          setEditable(false)
          setExternalCode(data.translated_external_code_id)
          return setFormAlerts(removeAlertByField(formAlerts, 'external_code'))
        }
        // Encontramos un gasto con este external_code. Por lo tanto, no puede ser utilizado de nuevo a menos que sea reingreso
        if (translatedExternalCode !== reentryCode) {
          requirementByExternalCodeErrorResponse()
        }
      })
      .catch(err =>{
        // Error
        requirementByExternalCodeErrorResponse()
      })
      .finally(() => {
        closeAlert()
      })
  }

  const handleTranslatedExternalCodeBlur = () => {
    getRequirementByTranslatedExternalCode()
  }

  const handleTranslatedExternalCodeChange = (event) => {
    let value = event.target.value
    setTranslatedExternalCode(value)
  }

  const getOptions = () => {
    let options = [
      {
        value: '1',
        label: 'Solicitar a compañía'
      },
      {
        value: '2',
        label: 'Ingreso manual'
      }
    ]
    if (hasExternalCodeGetter) {
      options = [...options, {
        value: '3',
        label: 'Sin folio externo'
      }]
    }
    return options
  }

  return (
    <>
      <div className={'form-group col form-row justify-content-center align-items-center'}>
        <Label htmlFor={'new_external_code_state'} className={'col flex-grow-0'}>Folio:</Label>
        {isEditable && editable ? (
          <>
            <Select
              id={'new_external_code_state'}
              value={externalCodeState}
              onChange={handleExternalCodeStateChange}
              className={'col'}
              options={getOptions()}
            />
            {externalCodeState === '2' && (
              <>
                {isStringExternalCode ? (
                  <>
                    <Input
                      id={'new_external_code'}
                      type={'text'}
                      value={translatedExternalCode}
                      disabled={disabledExternalCode}
                      onChange={handleTranslatedExternalCodeChange}
                      onBlur={handleTranslatedExternalCodeBlur}
                      className={'col ml-2' + getInputErrorClass(formAlerts, 'external_code')}
                    />
                    <FormAlert
                      message={getInputErrorMessage(formAlerts, 'external_code')}
                    />
                  </>
                ) : (
                  <>
                    <Input
                      id={'new_external_code'}
                      type={'number'}
                      step={'1'}
                      value={externalCode}
                      disabled={disabledExternalCode}
                      onChange={handleExternalCodeChange}
                      onBlur={handleExternalCodeBlur}
                      className={'col ml-2' + getInputErrorClass(formAlerts, 'external_code')}
                    />
                    <FormAlert
                      message={getInputErrorMessage(formAlerts, 'external_code')}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <EditableValue
            setEditable={setEditable}
            isEditable={isEditable}
          >
            <Label
              className={'col flex-grow-0'}
            >
              <strong>{isStringExternalCode ? translatedExternalCode : externalCode}</strong>
            </Label>
          </EditableValue>
        )}
      </div>
    </>
  )
}

export default RequirementId